/* Main components and structure */

/* Container for console */

#hummingbird {
	position: sticky;
	bottom: -2px;
	z-index: 1000;
}

#console {
    width: 100%;
    border-style: solid;
    border-color: #231f1f;
    border-width: 1px 0 0 0;
    max-height: 100vh;
}

#hummingbird_static {
    @extend #hummingbird;
    width: 100%;
    display: none;
}

#console_static {
    @extend #console;
}

#console-add-players_static {
    @extend #console-add-players;
}


// Disable scrolling on body if the console takes up the whole or almost the whole screen

body[no-scroll] {
    overflow: hidden;
}

.mobile-menu-open #console {
    display: none;
}

@media screen and (max-height: 700px) {
    #console {
        overflow-y: auto;
    }
}

#console {
    width: 100%;
    background-color: #231f1f;
}

#console
    .width-55__pad-bottom:not(.happy-padding):not(.tiny-vertical-padding):not(.vertical-happy-padding):not(.happy-padding-bottom) {
    padding-bottom: 0;
}

.booking-not-started:not(.booking-started) .landing-page ~ #hummingbird .no-game-selected {
    // Both .booking-not-started and .booking-started to exist if user hits back button on console. This ensures console doesn't dissappear when back button used (which changes booking state)
    display: none;
}

/* Main Headings */

#console .console-title {
    cursor: pointer;
    position: relative;
    width: 100%;
    background-color: #b82026;
    color: white;
    margin: 0;
    padding: 0.25rem 1rem;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    text-transform: none;
}
#console .console-title:hover {
    text-shadow: 0 0 0.5em white;
}

// Make exception to above if player has dismissed the switch game dialog

#console[data-dismissed-switch-game] .console-back {
    display: block !important;
}

#console[data-switch-game] .drop-down {
    display: none;
}

#console .console-back-close {
    display: none;
}

#console[data-console-state="details"]:not(.console-minimised) .console-back-close {
    display: block;
}

#console[data-console-state="details"] .console-back {
    display: none;
}

#console .console-back,
#console .console-back-close {
    position: absolute;
    width: auto;
    cursor: pointer;
    z-index: 2;
}

#console .console-back {
    left: 0;
    top: 2px;
    height: 1.9rem;
    transform: rotate(90deg);
}

#console .console-back-close {
    left: 5px;
    top: 5px;
    height: 1.4rem;
}

#console_static[data-console-state="gift-details"] .console-back {
    display: none;
}

#console .console-title .drop-down {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 0.95rem);
    height: 1.9rem;
    width: auto;
    transform: none;
    cursor: pointer;
    z-index: 2;
}

#console .console-title .drop-down-glow,
#console .console-back-glow,
#console .console-back-close-glow {
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
}

#console .console-title .drop-down:hover + .drop-down-glow,
#console .console-back:hover + .console-back-glow,
#console .console-back-close:hover + .console-back-close-glow {
    opacity: 1;
}

#console .switch-game {
    font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
    font-size: 0.7em;
}

#console.console-minimised .console-title .drop-down {
    transform: rotate(180);
    transform: scaleY(-1);
}

.console-title img,
.console-sub-title img {
    height: 1em;
    width: auto;
}

.console-sub-title {
    cursor: pointer;
    padding: 0.25em 1rem;
    position: relative;
}

.console-sub-title .close {
    filter: #{"invert()"};
    position: absolute;
    right: 0.25rem;
    height: 1.5em;
    width: 1.5em;
    display: inline-block;
    padding: 0.35em;
    top: 0.1em;
    cursor: pointer;
}

@media (max-width: 600px) {
    #console .console-title {
        padding: 0.25rem 0.5rem;
    }
    #console .console-title span {
        display: block;
    }
    #console .console-back {
        top: 14px;
    }

    #console_static[data-console-state="gift-booker-details"] .console-back,
    #console_static[data-console-state="gift-details"] .console-back {
        top: 2px;
    }
    
    #console .console-back-close {
        top: 19px;
        left: 5px;
    }
}

/* Console interface form components */

#console label {
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    margin-bottom: 0;
    display: block;
}

#console input[type="text"],
#console input[type="email"],
#console input[type="tel"],
#console input[type="password"],
#console input[type="date"],
#console input[type="time"],
#console input[type="datetime-local"],
#console select {
    padding: 1em;
    width: 100%;
    border-radius: 0.3em;
    border: none;
    margin-top: 1em;
    margin-bottom: 1em;
}

#console select {
    /* values deliberatly in px to avoid size jumping from user agent when active */
    height: 36px;
    padding: 5px 18px;
    margin: 18px 18px 18px 0;
    max-width: 25rem;
}

.console .button {
    height: 2rem;
    padding-right: 3em;
}

.message--switch-game .button + .button {
    margin-left: 1rem;
}

#console .button.pill {
    flex: 0 0;
}

#console input[type="checkbox"] + label::before,
#console input[type="checkbox"] + label::after {
    border-color: #aaa;
    margin-bottom: 4px;
}

#hummingbird-payment-form {
    padding: 0;
}

#hummingbird-payment-form.gift-spaces-payment{
    padding: 0.25rem 0;
}

.player-count-heading,
.pre-payment-date-heading {
    text-align: left;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    margin-bottom: 0.2em;
}

.upper-details-players > div:first-child,
.upper-details-date > div:first-child {
    flex: 1 1;
}

#console .spaces-payment,
.booker-details {

    .reserve-date-wrap {
        text-align: left;
        margin-top: 1.1rem;
    }
    
    label {
        font-size: 1rem;
        font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
        margin-bottom: 0;
    }
} 

.visuallyhidden {
    opacity: 0;
    display: block;
    height: 0;
    width: 0;
}

#console #hummingbird-payment-form label input {
    margin-top: 0;
    padding: 0.75em 1em;
}

.StripeElement {
    min-width: 308px;
    box-sizing: border-box;
    min-width: max-content;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
}

#console_static .gift-spaces-payment .StripeElement {
    min-width: unset;
}

#card-element.StripeElement {
    height: 44.5px;
    padding: 0.75em 1em;
    border-radius: 0.3em;
}

#payment-request-button--wallet,
#checkout-buttons {
    display: none;
}

#payment-request-button--wallet.StripeElement,
#checkout-buttons.StripeElement {
    background: transparent;
}

.card-button__full-text,
.card-button__short-text {
    display: none;
}

@media screen and (max-width: 999px) {
    .card-button__full-text {
        display: inline-block;
    }
}

@media screen and (min-width: 701px) and (max-width: 999px) {
    .gift-spaces-payment #payment-request-button--card {
        background-image: none;
    }
    .gift-spaces-payment {
        .card-button__full-text {
            display: none;
        }
        .card-button__short-text {
            display: inline-block;
        }

    }
}

@media screen and (min-width: 1000px) {
    #payment-request-button--card {
        background-image: none;
    }
    .card-button__short-text {
        display: inline-block;
    }
}


.card-payment-providers-wrapper {
    display: none;
}

.card-payment-providers-wrapper {
    width: 80%;
    max-width: 100%;
    gap: 1rem;
    justify-content: center;
    margin: 0.75rem auto;
    @media screen and (min-width: 480px) {
        gap: 1.5rem;
    }
}
  
.card-payment-provider-logo {
    flex: 0 1 auto;
    display: block;
    max-height: 1.5rem;
    @media screen and (min-width: 480px) {
        max-height: 1.75rem;
    }
}



#console .reserve-date-wrap label {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

#console .total-cost {
    margin: 0;
    font-size: 1.35rem;
    @media screen and (min-width: 701px) {
        margin: 0.5rem 0;
    }
}

.console .flexible-message {
    max-width: 19rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    font-size: 0.95rem;
    text-align: center;
    @media (max-width: 900px) {
        font-size: 0.8rem;
        line-height: 1;
        margin-top: 0.5rem;
        @media (max-width: 700px) {
            margin-top: 0.75rem;
            text-align: center;
        }
    }
}

.payment-buttons-wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 2.8rem;
    grid-auto-columns: 1fr;
    gap: 0.56rem;
    flex-grow: 1;
    margin: 0.28rem 0;
    .button {
        min-width: 185px;
        height: 2.8rem;
    }
}


#payment-request-button--klarna,
.button--klarna {
    &.button.white-base {
        background: white;
        padding-right: 1em;
    }
        
    img {
        height: 37%;
        margin: auto;
    }
    &::after {
        display: none;
    }
}

.StripeElement {
    box-sizing: border-box;
    min-width: max-content;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    .button {
        min-width: 185px;
    }
}

#console .contact-field {
    position: relative;
}

#console .field-message {
    display: none;
}

#console .has-message .field-message {
    display: block;
}

#console .field-message {
    position: absolute;
    background-color: #b82026;
    color: white;
    left: 10%;
    top: -37px;
    padding: 0.5em;
    font-family: "RajdhaniSemiBold";
    box-shadow: 3px 4px 5px 2px rgba(0, 0, 0, 0.4);
}

#console .has-message::after {
    content: "";
    position: absolute;
    display: inline-block;
    background-size: contain;
    width: 1.8em;
    height: 2.2em;
    right: 0.5em;
    bottom: 1em;
    background-repeat: no-repeat;
}

#console .has-message.error::after {
    background-image: url(../images/red-alert-icon.png);
}

#console .has-message.good::after {
    background-image: url(../images/green-tick.png);
}

#console .field-message::after {
    content: "";
    position: absolute;
    left: 20%;
    bottom: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #b82026;
    clear: both;
}

#console .voucher-code {
    position: relative;
}

#console .voucher-code.has-message::after {
    bottom: 0;
}

#console .voucher-code .field-message {
    top: -27px;
}

#console .voucher-code.message-long .field-message {
    top: -60px;
}

#console .voucher-block {
    button.refresh {
        display: none;
    }
}

#console .voucher-block.voucher-validated {
    button.validate {
        display: none;
    }
    button.refresh {
        display: block;
    }
}

#console [type="checkbox"] ~ .hide {
    display: none;
}

#reserve-date-check:checked ~ .upper-details-date {
    display: flex;
    align-items: center;
}

#console #redeem-voucher[type="checkbox"]:checked ~ .hide {
    display: inline-flex;
}

#console .lower-pre-payment-area .button {
    margin-bottom: 1rem;
}

#console-voucher .gift-code-block {
    margin-top: 0;
}

#console .voucher-block .button + .button {
    margin-left: 0;
}

.voucher-name {
    margin-left: 0;
}

.voucher-name span {
    font-size: 1rem;
}

.voucher-name b {
    text-transform: uppercase;
}

#console-add-players .button-wrap {
  align-items: center;
  position: relative;
}

[data-console-state="add-players"] [data-console-component="player_count"] .per-item-price__message {
    font-size: 1rem;
}

#console .spaces-payment,
.booker-details {
    #redeem-voucher[type="checkbox"]:checked ~ .hide {
        flex-direction: row;
        justify-content: space-between;
    }
}

.voucher-wrap .voucher-name {
    text-align: left;
    line-height: 0.8;
    margin-top: 0.25em;
    margin-right: 0.75em;
}

#console .spaces-payment,
.booker-details {
    .voucher-block .button {
        flex: 1 0;
        padding-left: 0.5rem;
        margin-bottom: 0;
        margin-top: 0.5em;
    }
    
    .gift-code-block {
        margin-right: 1rem;
        display: block;
        margin-top: 0;
    }
    
    .voucher-field {
        margin-bottom: 0;
    }
    
    section.gift-code {
        margin: 0;
        flex: 2 0 50%;
    }
}

.console .voucher-block .button {
    height: 2.55rem;
}

#console .voucher-field.gift-code-input {
    margin-top: 0.5em;
    margin-bottom: 0;
    padding: 0.65em 1em;
    height: auto;
}

input.redeem-voucher:not(:checked) ~ .voucher-block {
    display: none;
}

#console .option-disabled {
    opacity: 0.3;
    pointer-events: none;
}


.choose-payment-method {
    grid-area: details;
}

.upper-details-players {
    grid-area: players;
}

.reserve-date-wrap {
    grid-area: slot;
}

.voucher-wrap {
    grid-area: voucher;
}

.form-row.pre-payment-personal-details {
    grid-area: details;
}

.lower-pre-payment-area {
    grid-area: totals;
}

.console .flexible-message {
    grid-area: messaging;
}

#console[data-slot=""][data-voucher]:not([data-voucher=""]) {
    .voucher-wrap {
        grid-area: slot;
    }

    .reserve-date-wrap {
        grid-area: voucher;
    }
}

#voucher-redemption-button {
    display: block;
    text-align: left;
}

@media (max-width: 480px) {
    #console-add-players .player-count-label {
        min-width: unset;
    }

    #console-add-players .player-count-value {
        width: 2.5rem;
        margin: 0 1rem;
    }

    #console-add-players .player-count-change {
        width: 0.8em;
        height: 0.8em;
        margin-top: 0.2em;
    }

    #console-add-players .button {
        padding-left: 0.7em;
        padding-right: 3em;
    }

    #console-add-players .player-count-right-inner {
        margin: 0.25rem 0 0.25rem auto;
    }

    #console-add-players .player-count-label h5 {
        text-align: left;
        margin-bottom: 0.2em;
    }

    #console-details .player-count-value {
        margin: 0 1rem;
    }
}


@media (max-width: 900px) {
    .console .cost-and-buy-wrapper .button + .button, 
    .console .choose-payment-method .button + .button {
        margin-top: 0.5em !important;
    }
}

@media screen and (max-width: 800px) {

    #console_static .gift-spaces-payment .payment-buttons-wrapper {
        grid-auto-flow: row;
    }

    #console_static .system-form.spaces-payment.gift-spaces-payment {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "players" "totals" "details" "voucher" "messaging";
    }

    #console_static .gift-spaces-payment .choose-payment-method {
        width: unset;
    }

    [data-console-component=gift-details] .gift-spaces-payment .upper-details-players {
        justify-content: center;
    }

    #console_static .gift-spaces-payment .upper-details-players {
        width: auto;
        padding-top: 0.5rem;
    }

    #console_static .choose-payment-method .button,
    #console_static .choose-payment-method .StripeElement {
        min-width: max-content;
        margin: 0 auto !important;
        @media (min-width: 701px) {
            width: 70%;
        }
    }
}

@media (max-width: 700px) {
    [data-console-state="add-players"]#console #console-add-players .width-55__pad-bottom {
        padding-bottom: 1rem;
    }

    [data-console-state="add-players"] .per-item-price__message {
        text-align: right;
        max-width: 6rem;
        float: right;
        position: relative;
        bottom: 0.25rem;
    }

    #console-details .player-count-right-inner {
        justify-content: flex-end;
        margin-right: 0;
    }

    #console-details .player-count-change.player-count-plus {
        margin-right: 0;
    }

    [data-console-state="add-players"] [data-console-component="player_count"] .button-wrap {
        display: none;
    }

    [data-console-state="add-players"] .buy-voucher-wrap {
        text-align: center;
    }

    #hummingbird-payment-form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em 0em;
        grid-template-areas:
            "players"
            "slot"
            "details"
            "totals"
            "messaging"
            "voucher";
    }

    #hummingbird-payment-form.spaces-payment {
        grid-template-areas: 
            "players"
            "slot"
            "totals"
            "details"
            "messaging"
            "voucher";
    }

    #hummingbird-payment-form.gift-spaces-payment,
    .gift-booker-details {
        grid-template-areas: 
            "players"
            "totals"
            "details"
            "voucher"
            "messaging";
    }

    #hummingbird-payment-form.booker-details {
        grid-template-areas: 
            "players"
            "slot"
            "details"
            "totals"
            "messaging"
            "voucher";
    }

    #hummingbird-payment-form.gift-booker-details {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
            "players players players"
            "details details details"
            "voucher totals null"
            "messaging messaging messaging";
    }

    #console .spaces-payment,
    .booker-details {
        .reserve-date-wrap {
            margin-top: 0;
            margin-bottom: 0;
        }
        
        .gift-code-block {
            margin-left: 0;
            margin-bottom: 0.5em;
            margin-top: 0;
        }
    }

    [data-console-state="voucher"] [data-console-component="voucher"] {
        margin-bottom: -0.5rem;
        max-width: 26rem;
        margin-left: auto;
        margin-right: auto;
    }

    .voucher-toggle {
        text-align: center;
    }

    .voucher-wrap--complete {
        margin-bottom: 0;
    }

    .voucher-wrap .voucher-name {
        margin-bottom: 0;
    }

    .player-count-heading,
    .pre-payment-date-heading,
    .voucher-wrap--complete {
        margin-top: 0.5rem;
    }

    .console .cost-and-buy-wrapper,
    .console .choose-payment-method {
        .button{
            width: auto;
            min-width: 80%;
            height: 2.8rem;
        }
    }

    #console .choose-payment-method{
        .button,
        .StripeElement {
            width: 80%;
            min-width: max-content;
            margin: 0 auto !important;
        }

    }

    #console_static div#checkout-buttons {
        min-width: unset;
    }

    #console .spaces-payment,
    #console .booker-details {
        .voucher-block .button {
            margin-bottom: 0.2em;
            margin-top: 0.25em;
        }
    } 
}

@media (min-width: 701px) {
    #console-add-players > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    [data-console-state="add-players"]{

        [data-console-component="player_count"] {
            
            .player-count {
                margin: 0;
                flex: 1 1;
            }
            
            .player-count-right {
                margin-left: 1rem;
            }
            
            .player-count .player-count-right-inner {
                justify-content: flex-start;
            }
            
            .player-count .button.pill {
                display: none;
            } 
            
            .per-item-price__message {
                flex: 1 1;
                min-width: 110px;
                padding: 0.25rem 1.5rem;
            }
        }
        
        .player-count-value {
            margin: 0;
        }
        
        .button-wrap {
            flex: 1 1;
        }
    }

    // Layout positioning for landscape mobile and up

    #hummingbird-payment-form.spaces-payment {
        grid-template-areas: 
            "slot players totals"
            "slot details messaging"
            "voucher details messaging";
    }
    #hummingbird-payment-form.booker-details {
        grid-template-areas:
            "players details totals"
            "players details totals"
            "slot details totals"
            "slot details messaging"
            "voucher details messaging"
            "voucher details messaging"
    }
    #hummingbird-payment-form.gift-booker-details {
        grid-template-areas:
            "players details totals"
            "players details messaging"
            "players voucher messaging"
    }
}

.select-date .player-count-block__wrapper,
.select-time-slot .player-count-block__wrapper {
    margin-bottom: 1em;
    
    @media screen and (max-width: 890px) {
        margin: 0 1em 1em;
    }
    
    @media screen and (max-width: 600px) {
        margin: 0;
    }
}

.select-date .player-count-block__wrapper {
    @media screen and (max-width: 600px) {
        position: relative;
        bottom: 0.75rem;
    }
}

.select-time-slot {
    overflow-y: auto;
    max-height: calc(100vh - 13rem);
    @media screen and (max-width: 890px) {
        max-height: calc(100vh - 11rem);
    }
}

.select-time-slot .player-count-block__wrapper {
    padding: 1rem 1rem 0;
}

.select-date .player-count-right-inner,
.select-time-slot .player-count-right-inner {
    margin-top: 0;
    margin-bottom: 0.25rem;
}

.select-date .player-count-block-full,
.select-time-slot .player-count-block-full {
    width: 100%;
    align-items: center;
}

.upper-details-players .player-count-right .player-count-value {
    width: 3.5rem;
    text-align: center;
    margin-top: 0;
    padding: 0.1rem 1rem;
    margin-bottom: 0;
}

.upper-details-players .player-count-right .player-count-change {
    margin-top: 0.2em;
}

.upper-details-players .player-count-right .player-count-right-inner {
    margin: 0.25rem auto;
}

.player-count-heading {
    float: left;
    text-transform: capitalize;
    margin-left: 0;
    margin-right: 0.5rem;
}

.player-count-heading .player-count-current {
    display: none;
}

.upper-details-players > div:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.gift-spaces-payment,
.gift-booker-details {
    .reserve-date-wrap {
        display: none;
    }
    .voucher-wrap {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
}


@media screen and (max-width: 700px) {
    
    .gift-spaces-payment .voucher-wrap {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}

/* Console states - The same states /classes are activated on mobile and desktop. Using media queries to show relevant screens for device size */

/* Hide all components by default */

[data-console-component] {
    display: none;
}

// console-minimised is not really a state like the rest as it can be combine
// with other states on mobile to get back to where you were

#console.console-minimised {
    [data-console-component] {
        display: none !important;
    }

    [data-console-component="continue-booking"] {
        display: block !important;
    }

    [data-console-component="continue-booking"] .console-sub-title {
        margin-bottom: 0;
        margin-top: 0;
    }
}

[data-console-state="add-players"] {
    [data-console-component="player_count"] {
        display: block;
    }
}

[data-console-state="details"] {
    [data-console-component="details"] {
        display: block;
    }
}

[data-console-state="booker-details"] {
    [data-console-component="booker-details"] {
        display: block;
    }
}

[data-console-state="gift-details"] {
    [data-console-component="gift-details"] {
        display: block;
    }
}

[data-console-state="gift-booker-details"] {
    [data-console-component="gift-booker-details"] {
        display: block;
    }
}

[data-console-state="voucher"] {
    [data-console-component="voucher"] {
        display: block;
    }
}

[data-console-state="choose-date"] {
    [data-console-component="choose-date"] {
        display: block;
    }
}

[data-console-state="choose-time"] {
    [data-console-component="choose-time"] {
        display: block;
    }
}

[data-console-state="update-players-inline"],
[data-console-state="voucher"] {
    [data-console-component="details"],
    [data-console-component="booker-details"] {
        display: block;
    }
}

// Details Screen - Landscape 

@media screen and (min-width: 701px) and (max-width: 900px) {
    .payment-buttons-wrapper{
        .button {
            width: 100%;
        } 

    }
}

// Details Screen - Desktop

@media screen and (min-width: 701px) {
    #console .spaces-payment,
    .booker-details {
        display: grid;
        grid-template-columns: minmax(150px, 1fr) minmax(min-content, 1.5fr) minmax(270px, 1fr);
        gap: 0 calc(4vw - 1rem);
    }

    .update-voucher-link,
    .update-players-link {
        align-self: center;
    }

    #console .spaces-payment,
    .booker-details{
        .reserve-date-wrap {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            
            & label {
                margin-bottom: 0.25rem;
                margin-top: 0;
            }
        }
        .voucher-wrap {
            text-align: left;
        }
    }

    #console .spaces-payment:not(.gift-spaces-payment) .choose-payment-method {
      display: flex;
    }

    .payment-buttons-wrapper {
        flex-grow: 1;
        .button {
            min-width: 124px;
            margin: 0.25em 0;
        }
    }
    
    @media (min-width: 900px){
        .payment-buttons-wrapper {
            flex-wrap: wrap;
            margin-left: -0.25rem;
            margin-right: -0.25rem;
            .button {
                flex-grow: 1;
                margin: 0;
            }
        }
    }

    .form-row.pre-payment-personal-details {
        grid-area: details;
    }

    .pre-payment-date-heading {
        margin-top: 0.25em;
    }

    #console .spaces-payment.gift-spaces-payment {
        display: flex;
        gap: 0 calc(2vw - 1rem);
    }

    .gift-spaces-payment {
        .payment-buttons-wrapper {
            grid-auto-flow: column;
            margin: 0;
            padding: 0.5rem 0 0.5rem;
            .button,
            .button + .button {
                margin: 0 !important;
            }
        }

        .choose-payment-method {
            display: flex;
            order: 2;
            width: max-content;
            flex-grow: 1;
            flex-shrink: 0;
        }

        .player-count-heading {
            margin-right: 1rem;
            margin-bottom: 0;
        }

        .player-count-value {
            margin: 0 0.5rem;
        }
        
        .upper-details-players {
            width: max-content;
            order: 0;
        }
        
        .voucher-wrap {
            display: none;
        }
        
        .flexible-message {
            display: none;
        }
        
        .lower-pre-payment-area {
            order: 1;
            flex: 1 1 auto;
            flex-grow: 1;
        }
    }

    .booker-details {
        .upper-details-players {
            align-items: flex-start;
        }
    }

    #console .booker-details .total-cost {
        margin-top: 0;
    }
  
}


#console_static .gift-spaces-payment .choose-payment-method {
    @media screen and (min-width: 768px) {
        flex: 1 1 auto;
    }
}

#console_static .gift-spaces-payment .upper-details-players {
    margin: auto;
    @media screen and (min-width: 768px) {
        flex: 0 0 auto;
        margin-right: 2rem;
        margin-left: 0;
    }
}

    
@media (min-width: 1000px){
    .payment-buttons-wrapper {
        grid-auto-flow: column;
    }
}

// Time slot screen

.slot-list {
    max-height: calc(100vh - 3.91rem);
    overflow: auto;
    padding-bottom: 3rem;
}

// IOS fix for menu overlap on timeslots scroll
@supports (-webkit-touch-callout: none) {
    .slot-list {
        padding-bottom: 8rem;
    }
}

// Mobile full screen adjustments - Calendar

@media screen and (max-width: 600px) {
    .select-date {
        margin-top: 0;
    }

    .booking-calendar {
        margin: 0;
    }

    .key-filter-card {
        margin: 0 0 1em;
    }

    .select-date-key {
        width: 320px;
        margin: auto;
    }

    .circle-unavailable {
        border: solid thin #434343;
        width: calc(1.5em + 2px);
        height: calc(1.5em + 2px);
    }

    .fc-scroller.fc-day-grid-container {
        margin-bottom: 1em;
    }

    .select-date-key .key-text {
        height: 0;
        opacity: 0;
    }
}

// Mobile full screen adjustments - pre-payment details

@media screen and (max-width: 700px) and (max-height: 800px) {
    #console .total-cost {
        margin-bottom: 0;
        margin-top: 0;
    }
}

@media screen and (max-width: 700px) and (max-height: 750px) {
    #console input[type="text"],
    #console input[type="email"],
    #console input[type="tel"],
    #console input[type="password"],
    #console input[type="date"],
    #console input[type="time"],
    #console input[type="datetime-local"],
    #console select {
        margin-bottom: 0.5em;
    }

    #hummingbird-payment-form {
        gap: .75em 0em;
    }

    .player-count-heading,
    .pre-payment-date-heading {
        margin-top: 0;
    }

}

@media screen and (max-width: 700px) and (max-height: 450px) {

    #console #hummingbird-payment-form label input {
        padding: 0.5em 1em;
    }

    #console .reserve-date-wrap label {
        margin-top: 0;
    }
}

// Mobile adjustment for narrow screen on select game

@media screen and (max-width: 390px) {
    #console .select-game select {
        padding: 2px;
        text-align: center;
    }
}

// Adjustments for narrow desktop and tablet

@media screen and (min-width: 701px) and (max-width: 825px) {
    #console .spaces-payment,
    .booker-details {
        gap: 0 calc(4vw - 1rem);

        label {
            font-size: 0.95rem;
        }

        .fast-select-date-right,
        .player-count-right {
            flex-grow: 0;
        }

        .total-cost {
            line-height: 1;
        }

        .flexible-message {
            line-height: 1.1;
        }

        .upper-details-players {
            .player-count-heading {
                margin-bottom: 0;
            }
            .player-count-right {
                .player-count-right-inner {
                    margin-top: 0;
                    .player-count-value {
                        line-height: 1.5;
                        margin: 0 0.75rem;
                    }
                    .player-count-change {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .gift-code-block {
            margin-left: 0;
            margin-right: 0.5rem;
            .voucher-field.gift-code-input {
                padding: 0.5em;
            }
        }
    }
}

@media screen and (min-width: 701px) and (max-width: 840px){

    #console .spaces-payment,
    .booker-details {
        grid-template-columns: minmax(160px, 1fr) auto minmax(150px, 1fr);

        .voucher-block {
            .button {
                font-size: 14px;
            }
            .voucher-field.gift-code-input {
                padding: 0.3em;
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }
        }
    }

    .player-count-block-minimised {

        .player-count-block-minimised-right {
            align-self: center;
        }
    }

    .upper-details-players .player-count-right {
        margin: 0 auto;

        .player-count-change {
            margin: 0.2em 0 0;
        }

        .player-count-value {
            margin: 0 1rem;
        }
    }
}


@media screen and (min-width: 900px) and (max-width: 1100px){
    #console .spaces-payment,
    .booker-details {
        grid-template-columns: minmax(160px, 275px) auto minmax(150px, 275px);
    }
}


// Things that should not be cascaded over

@media screen and (max-width: 600px) {
    #console .hide-on-mob {
        display: none;
    }
}

@media screen and (min-width: 600px) {
    #console .hide-on-non-mob {
        display: none;
    }
}


// Backup for browsers not supporting sticky 
@supports not (position: sticky){
    #console {
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }

    .footer-address {
        padding-bottom: 7rem;
    }
    
    @media screen and (min-width: 600px){
        .console-minimised .footer-address {
            padding-bottom: 5rem;
        }
    }
}

#hummingbird_static ~ footer .footer-address {
    padding-bottom: 7rem;
}

@media screen and (max-width: 700px){
    #hummingbird_static.hummingbird_static--add-players ~ footer .footer-address {
        padding-bottom: 9em;
    }

    #hummingbird_static.hummingbird_static--voucher ~ footer .footer-address {
        padding-bottom: 8em;
    }
}


@media screen and (min-width: 600px){
    .console-minimised #hummingbird_static ~ footer .footer-address {
        padding-bottom: 5rem;
    }
}

// Error popups

.error-popup {
    background-color: #231f1f;
    width: 500px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
}

.error-popup h2 {
    width: 100%;
    background-color: #b82026;
    color: white;
    margin: 0;
    font-size: 1.5em;
    padding: 0.25rem 1rem;
    text-shadow: 0 0 0.5em white;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    text-transform: none;
}

.error-popup p {
    padding: 1em;
}

.error-popup button.primary {
    float: right;
    margin: 1em !important;
}

.fancybox-container {
    z-index: 2147483647;
}

.fancybox-content form {
    padding: 1em;
}

// Name fields fix

.name-fields {
    display: flex;
}

.contact-firstname {
    width: 45%;
    margin-right: 5px; 
}

.contact-lastname {
    flex: 1;
}

.buy-tickets-pill-wrapper {
    width: max-content;
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
}
.buy-tickets-pill {
    padding: 1rem 1.5rem;
    background-color: #b82026;
    border-radius: 90px;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    line-height: 1;
    font-size: 21px;
    &:hover {
        background-color: #d91a19;
        text-shadow: 0 0 0.5em white;
        transform: scale(1.05);
    }
}

// Select game screen

.select-game *:focus {
    outline: none;
}

.select-game .select-game-choices {
    display: none;
}

.select-game-choice, .select-game-choice:hover {
    color:inherit;
}

.select-game-toggle {
    width: 100%;
    display: block;
}

#console.select-game-open .select-game-choices {
    display: block;
}

#console.select-game-open .select-game-toggle .chevron {
    display: none;
}

#console.select-game-open .select-game-toggle {
    color: #666;
}

#console.select-game-open {

    .console-back {
        display: none;
    }

    .drop-down {
        display: block;
    }

    .select-game-closed-title {
        display: none;
    }

    .select-game-open-title {
        display: block;
    }

    .select-game-toggle {
        display:none;
    }

}

.select-game-choices {
    list-style: none;
    width: 100%;
    margin-bottom: 0;
}

.select-game-choice {
    width: 100%;
    display: block;
    text-align: center;
}

.select-game-choice:hover,
.select-game-toggle:hover {
    cursor: pointer;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
    background-color: #353535ff;
}

.select-game-choice-chevron {
    float: right;
}

.select-game {
    .chevron,
    .select-game-choice-chevron {
        background-image: url("../images/chevron_down.svg");
        width: 1rem;
        height: 1rem;
        display: inline-block;
        background-size: contain;
        transform: rotate(270deg);
        vertical-align: middle;
    }
}
// Player-count block mimimised / full

.player-count-block-minimised {
    flex: 1 1;

    .player-count-heading {
        float: none;
        margin-bottom: -0.1em;
    }

    .player-count-block-minimised-right{
        margin-left: 1rem;
    }
}

.player-count-block-full {
    display: none !important;
 }

.console-player-counter-active {

    .player-count-block-minimised {
       display: none !important;
    }

    .player-count-block-full {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
     }
}

.spaces-payment:not(.gift-spaces-payment) .console-player-counter-active .player-count-block-full {
    display: grid !important;
    grid-template-columns: 1fr max-content 1fr;
    width: 100%;
}

@media screen and (max-width: 700px){
    .console-player-counter-active .player-count-block-full {
        /* display: grid !important;
        grid-template-columns: 1fr max-content 1fr; */
        width: 100%;
    }
}

.select-date .player-count-block-full {
    display: flex !important;
}

.select-date .player-count-block-full,
.select-time-slot .player-count-block-full {
    justify-content: center;
    @media screen and (max-width: 700px) {
        display: flex;
    }
}

.select-time-slot .player-count-block__wrapper {
    margin: 0 auto 1em;
}

@media screen and (min-width: 462px) {
    .select-time-slot .player-count-block__wrapper {
        width: 450px;
    }
    .select-time-slot .fc-header-toolbar {
        width: 450px;
        margin: 1rem auto 0;
    }
}

[data-console-component="details"] .upper-details-players {
    justify-content: center;
}


// Monetary gift voucher 

.input-group {
    display: flex;
}

.system-form .upper-details-players .input-group {
    justify-content: center;
    flex-wrap: nowrap;
}

.input-group-addon {
    padding: 1px 0.5rem 1px 0.5rem;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.75;
    color: #000;
    text-align: center;
    background-color: #cbcbcb;
    white-space: nowrap;
    flex: 0 0 max-content;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

[data-console-component="gift-details"] .gift-spaces-payment .upper-details-players {
    align-items: center;
    @media screen and (max-width: 700px) {
        justify-content: center;
        padding-top: 0.5rem;
    }
}

.pre-input-text {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
    flex: 0 0 auto;
}

.gift-voucher-value {
    position: relative;
    z-index: 2;
    width: 4.5rem;
    flex: 0 0 4.5rem;
    padding: 6px 12px;
    margin: 0;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.1;
    text-align: left;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

.system-form input.gift-voucher-value {
    padding: 1px 0.25rem;
    margin: 0;
    font-family: revert;
    width: 5rem;
}

.prefix .gift-voucher-value {
    border-radius: 0px 4px 4px 0px;
}
.prefix .input-group-addon {
    border-right: 0;
    border-radius: 4px 0px 0px 4px;
}

.input-group-addon.prefix {
    border-radius: 4px 0px 0px 4px;
    border-right: 0;
}

// Gift booker details screen

.console [data-console-component="gift-booker-details"] .flexible-message {
  max-width: 100%;
}

@media (max-width: 700px) {
    [data-console-component="gift-booker-details"] #hummingbird-payment-form.gift-booker-details {
      grid-template-areas: "details details details" "voucher totals null" "messaging messaging messaging";
    }
  }
  

@media screen and (min-width: 701px) {
    [data-console-component="gift-booker-details"] #hummingbird-payment-form.gift-booker-details {
        grid-template-areas: "details totals" "details messaging" "voucher messaging";
    }

    #console_static .spaces-payment, 
    #console_static .gift-booker-details {
      grid-template-columns: minmax(min-content, 1.5fr) minmax(270px, 1fr);
    }
}
  


// Disable pay button when changing player count

.cost-and-buy-wrapper.disabled button {
    opacity: 0.5;
    pointer-events: none;
    cursor: wait;
}

// Ticker 

.ticker-wrapper {
    display: none;
}


.run-ticker {

    .ticker-wrapper {
        display: block;
    }

    .ticker {
        height: 3rem;
    }

    #console-add-players {
        .ticker-wrapper {
            position: absolute;
            margin: 0 auto;
        }

        .button-wrap .button {
            transition: none; // temporarily disable transitions so the button disappears instantly
            visibility: hidden; // doing it this way rather than display none to retain spacing

        }
    }

    .cost-and-buy-wrapper {
        .button {
            display: none;
        }

        .ticker-wrapper {
            max-width: 11rem;
            margin: 0 auto;
            align-items: center;
            justify-content: center;

            span {
                flex: 1 1;
            }

            .ticker {
                align-self: center;
                margin: auto;
                width: 3rem;
                flex: 0 0 3rem;
            }
        }
    }

}



// Voucher flash (pop-up) message 


.voucher-flash {
    display: none;
    pointer-events: none;
    border: 3px solid #b82026;
    background: rgba(35, 31, 31, 1);
    position: fixed;
    width: max-content;
    max-width: calc(100vw - 3.75rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.35em 1.5em;
    font-size: 1.35rem;
    text-align: center;
    z-index: 2;
    opacity: 0;
    animation: 3s 1 fadeOut ease;
    -webkit-animation: 3s 1 fadeOut ease;
    -moz-animation: 3s 1 fadeOut ease;
    -o-animation: 3s 1 fadeOut ease;
    -ms-animation: 3s 1 fadeOut ease;
} 

.voucher-just-added .voucher-flash {
    display: block;
}

@media screen and (min-width: 701px){
    .voucher-flash {
        font-size: 1.5rem;

    }
}

@keyframes fadeOut {
    0% {
      opacity:1;
    }
    66% {
        opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut {
    0% {
      opacity:1;
    }    
    66% {
        opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }   
    66% {
        opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-o-keyframes fadeOut {
    0% {
      opacity:1;
    }   
    66% {
        opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-ms-keyframes fadeOut {
    0% {
      opacity:1;
    }    
    66% {
        opacity:1;
    }
    100% {
      opacity:0;
  }
}


[data-route="gift"] {

    .buy-voucher-wrap {
        display: none;
    }

    .reserve-date-wrap {
        display: none;
    }

    .voucher-wrap {
        display: none;
    }

}


// checkout 

.console--always-open #console .drop-down {
    display: none;
}

.saver-price-info {
    background-image: url("../images/icons/info-icon-white.svg");
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-size: contain;
    vertical-align: middle;
    position: relative;
    bottom: 0.7px;
    left: 10px;
}


.tippy-box {
    border: solid white 1px;
}

.tippy-arrow {
    display: none;
}



